import { Component, OnInit, Input } from '@angular/core';
import { Event } from '../../../../models/event';
import {ActivatedRoute} from '@angular/router';
import { RegistrationService } from '../../../../services/registration.service';
import { Registration } from '../../../../models/registration';
import { EventService } from '../../../../services/event.service';
import { BezirkService } from '../../../../services/bezirk.service';
import { StammService } from '../../../../services/stamm.service';
import { TemplatePageService } from '../../../../services/template-page.service';
import { SubscriptionService } from '../../../../services/subscription.service';
import { UserService } from '../../../../services/user.service';
import {Observable} from 'rxjs';
import { User } from '../../../../models/user';
import { Router} from '@angular/router';
import { Subscription } from '../../../../models/subscription';
import { NotifyService } from '../../../../services/notify.service';
import { PfadiAktion2024Service } from '../../../../services/pfadi-aktion-2024.service';


@Component({
  selector: 'app-pfadi-aktion2024-add-tn',
  templateUrl: './pfadi-aktion2024-add-tn.component.html',
  styleUrls: ['./pfadi-aktion2024-add-tn.component.css']
})
export class PfadiAktion2024AddTNComponent implements OnInit {

  event_id: any;
  registration_id: any;
  subscription_id: any;
  params: any;
  event:  any;
  stamm:  any;
  bezirk:  any;
  registration: any;
  progress: any;
  user: any;
  templatePages: any;
  status: any;
  templatePage: any;
  templatePage_id: any;
  subscription: any;
  istGeschwister: boolean;

  lebensmUnvertOptions = [
    { name: 'Gluten', selected: false },
    { name: 'Eier', selected: false },
    { name: 'Fische', selected: false },
    { name: 'Erdnüsse', selected: false },
    { name: 'Sojabohnen', selected: false },
    { name: 'Laktose', selected: false },
    { name: 'Sellerie', selected: false },
    { name: 'Senf', selected: false },
    { name: 'Sesamsamen', selected: false },
    { name: 'Schwefeldioxid und Sulphite in Konzentrationen', selected: false },
    { name: 'Lupinen', selected: false},
  ]

  lebensmVerpfOptions = [
    { name: 'Vegetarisch', selected: false },
    { name: 'Vegan', selected: false },
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private bezirkService: BezirkService,
    private eventService: EventService,
    private registrationService: RegistrationService,
    private stammService: StammService,
    private subscriptionService: SubscriptionService,
    private templatePageService: TemplatePageService,
    private userService: UserService,
    private router: Router,
    private pfadiaktion2024Service: PfadiAktion2024Service,
    private notifyService: NotifyService
  ) { }

  addTN(subscriptiondata) {
    this.subscription = new Subscription()
    this.subscription.data = subscriptiondata
    this.subscription.data.lebensmUnvert = JSON.stringify(this.lebensmUnvertOptions)
    this.subscription.data.lebensmVerpf = JSON.stringify(this.lebensmVerpfOptions)
    this.pfadiaktion2024Service.addTN(this.subscription)
    .subscribe(
      subscription => {
        this.notifyService.snotifySuccess("Teilnehmer erfolgreich angelegt!")
        this.router.navigate(['/events/custom/pfadiaktion2024/registrations/']);
      },
      error => {
        this.notifyService.snotifyError("Beim Anlegen des Teilnehmers ist ein Fehler aufgetreten!")
        console.log(<any>error)
      }
    );
  }

  updateCheckedlebensmUnvert(option, event) {
    for (var i = 0; i < this.lebensmUnvertOptions.length; i++){
      if (this.lebensmUnvertOptions[i].name == option){
        this.lebensmUnvertOptions[i].selected = event.target.checked;
      }
    }
  }

  updateCheckedlebensmVerp(option, event) {
    for (var i = 0; i < this.lebensmVerpfOptions.length; i++){
      if (this.lebensmVerpfOptions[i].name == option){
        this.lebensmVerpfOptions[i].selected = event.target.checked;
      }
    }
  }

  ngOnInit(): void {
    this.event = new Array()
this.event.title = "Pfadi-Aktion"
this.event.imageLink = "https://anmeldung.dpsg-essen.de/assets/images/pfadiaktion_2024.jpg";
this.event.active = 1;

  }

}
