<div class="jumbotron">
    <div class="display-4">
      <h1 class="display-4">ANMELDUNG</h1>
      <h2 class="display-6">{{event.title | uppercase}}</h2>
    </div>
    <img src="/assets/images/pfadiaktion_2024.jpg" alt="">
  </div>
  <div class="alert alert-info">
    Liebe Pfadis,<br/>
    <br/>
    Endlich ist es soweit und die nächste Diözesanaktion der Pfadistufe steht an! <br/>
    Unter dem Motto "Grünes Licht" bieten wir euch die Möglichkeit eine spannende Übernachtung mit eurem Trupp und jeder Menge Pfadis aus dem DV zu erleben.<br/>
    <br/>
    Hier ein paar Rahmendaten:<br/>
    <br/>
    Wann? 08.-09.11.2024 Wo? PNZ (Stolzestr 3a, 45879 Gelsenkirchen)<br/>
    Preis? 10€ pro Person zzgl. individueller Stammesbeitrag für die Anreise<br/>
    <br/>
    Programm:<br/>
    Freitag: Treffen um 17Uhr. Wir machen ein Outdoornachtspiel. Bitte bringt euch entsprechende Kleidung mit.<br/>
    Samstag: Morgens wird noch gemeinsam gefrühstückt und gegen 11Uhr fahrt ihr wieder nach Hause.<br/>
    <br/>
    Wir freuen uns auf eine grandiose, grüne Übernachtung mit euch!<br/>
    <br/>
    Viele Grüße und GUT PFAD,<br/>
    euer Pfadi-DAK<br/>
    <br />
    <h6>Wichtige Dokumente</h6>
    <ul>
      <li>
        <a href="assets/pfadiaktion2024/Anmeldeschreiben_TN_2024_Gruenes Licht.pdf" target="_blank">Vorlage Anmeldung Trupp-Mitglieder</a>
      </li>
    </ul>
  </div>

  <form novalidate class="form-horizontal" (ngSubmit)="AddOrUpdateSubscription(AddOrUpdateSubscriptionForm.value)" #AddOrUpdateSubscriptionForm="ngForm">
    <div class="card mt-4">
        <div class="card-header">Stammesdaten</div>
        <div class="card-body">
            <div class="form-group">
                <label for="stamm_strasse" class="col-lg-10 control-label">Straße</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="stamm_strasse" name="stamm_strasse" placeholder="Straße" [(ngModel)]="subscription.stamm_strasse" #stamm_strasse="ngModel" required>
                  <div *ngIf="stamm_strasse.errors?.required && stamm_strasse.dirty" class="alert alert-danger">
                    Straße ist ein Pflichtfeld
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="stamm_hausnummer" class="col-lg-10 control-label">Hausnummer</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="stamm_hausnummer" name="stamm_hausnummer" placeholder="Hausnummer" [(ngModel)]="subscription.stamm_hausnummer" #stamm_hausnummer="ngModel" required>
                  <div *ngIf="stamm_hausnummer.errors?.required && stamm_hausnummer.dirty" class="alert alert-danger">
                    Hausnummer ist ein Pflichtfeld
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="stamm_plz" class="col-lg-10 control-label">PLZ</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="stamm_plz" name="stamm_plz" placeholder="PLZ" [(ngModel)]="subscription.stamm_plz" #stamm_plz="ngModel" required>
                  <div *ngIf="stamm_plz.errors?.required && stamm_plz.dirty" class="alert alert-danger">
                    PLZ ist ein Pflichtfeld
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="stamm_ort" class="col-lg-10 control-label">Ort</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="stamm_ort" name="stamm_ort" placeholder="Ort" [(ngModel)]="subscription.stamm_ort" #stamm_ort="ngModel" required>
                  <div *ngIf="stamm_ort.errors?.required && stamm_ort.dirty" class="alert alert-danger">
                    Ort ist ein Pflichtfeld
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="stamm_email" class="col-lg-10 control-label">E-Mail</label>
                <div class="col-lg-10">
                  <input type="email" class="form-control" id="stamm_email" name="stamm_email" placeholder="E-Mail" [(ngModel)]="subscription.stamm_email" #stamm_email="ngModel" required>
                  <div *ngIf="stamm_email.errors?.required && stamm_email.dirty" class="alert alert-danger">
                    E-Mail ist ein Pflichtfeld
                  </div>
                </div>
              </div>
        </div>
      </div>
      <div class="card mt-4">
        <div class="card-header">Stammesverantwortliche:r der Diözesanaktion</div>
        <div class="card-body">
            <div class="form-group">
                <label for="stave_nachname" class="col-lg-10 control-label">Nachname</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="stave_nachname" name="stave_nachname" placeholder="Nachname" [(ngModel)]="subscription.stave_nachname" #stave_nachname="ngModel" required>
                  <div *ngIf="stave_nachname.errors?.required && stave_nachname.dirty" class="alert alert-danger">
                    Nachname ist ein Pflichtfeld
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="stave_vorname" class="col-lg-10 control-label">Vorname</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="stave_vorname" name="stave_vorname" placeholder="Vorname" [(ngModel)]="subscription.stave_vorname" #stave_vorname="ngModel" required>
                  <div *ngIf="stave_vorname.errors?.required && stave_vorname.dirty" class="alert alert-danger">
                    Vorname ist ein Pflichtfeld
                  </div>
                </div>
              </div>
            <div class="form-group">
                <label for="stave_strasse" class="col-lg-10 control-label">Straße</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="stave_strasse" name="stave_strasse" placeholder="Straße" [(ngModel)]="subscription.stave_strasse" #stave_strasse="ngModel" required>
                  <div *ngIf="stave_strasse.errors?.required && stave_strasse.dirty" class="alert alert-danger">
                    Straße ist ein Pflichtfeld
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="stave_hausnummer" class="col-lg-10 control-label">Hausnummer</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="stave_hausnummer" name="stave_hausnummer" placeholder="Hausnummer" [(ngModel)]="subscription.stave_hausnummer" #stave_hausnummer="ngModel" required>
                  <div *ngIf="stave_hausnummer.errors?.required && stave_hausnummer.dirty" class="alert alert-danger">
                    Hausnummer ist ein Pflichtfeld
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="stave_plz" class="col-lg-10 control-label">PLZ</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="stave_plz" name="stave_plz" placeholder="PLZ" [(ngModel)]="subscription.stave_plz" #stave_plz="ngModel" required>
                  <div *ngIf="stave_plz.errors?.required && stave_plz.dirty" class="alert alert-danger">
                    PLZ ist ein Pflichtfeld
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="stave_ort" class="col-lg-10 control-label">Ort</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="stave_ort" name="stave_ort" placeholder="Ort" [(ngModel)]="subscription.stave_ort" #stave_ort="ngModel" required>
                  <div *ngIf="stave_ort.errors?.required && stave_ort.dirty" class="alert alert-danger">
                    Ort ist ein Pflichtfeld
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="stave_email" class="col-lg-10 control-label">E-Mail</label>
                <div class="col-lg-10">
                  <input type="email" class="form-control" id="stave_email" name="stave_email" placeholder="E-Mail" [(ngModel)]="subscription.stave_email" #stave_email="ngModel" required>
                  <div *ngIf="stave_email.errors?.required && stave_email.dirty" class="alert alert-danger">
                    E-Mail ist ein Pflichtfeld
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="stave_telefon" class="col-lg-10 control-label">Telefonnummer</label>
                <div class="col-lg-10">
                  <input type="text" class="form-control" id="stave_telefon" name="stave_telefon" placeholder="Telefonnummer" [(ngModel)]="subscription.stave_telefon" #stave_telefon="ngModel" required>
                  <div *ngIf="stave_telefon.errors?.required && stave_telefon.dirty" class="alert alert-danger">
                    Telefonnummer ist ein Pflichtfeld
                  </div>
                </div>
              </div>
        </div>
        
      </div>

<br />

    <div class="form-group">
           <div class="col-lg-10 col-lg-offset-2">
             <a [routerLink]="['/events']" class="btn btn-default">Zurück</a>
             <button type="submit" class="btn btn-primary" [disabled]="AddOrUpdateSubscriptionForm.invalid || event.active == 0 || closed == 1">Speichern</button>
           </div>
         </div>
  
  </form>
  
  <hr />
  
  
  
  <div class="col-md-12 col-md-offset-1">
    <div>
      <a [routerLink]="['/events', 'custom', 'pfadiaktion2024', 'addTN']" class="btn btn-success" *ngIf="event.active == 1 && closed == 0 && subscription_id != undefined">Neue/n Teilnehmende/n anlegen</a>
      <a (click)="export()" class="btn btn-default float-right" role="button" aria-expanded="false" *ngIf="subscription_id != undefined"><i class="fa fa-file-excel-o"></i> Export Excel</a>
  
    </div>
  </div>
  <br />
  <div class="col-md-12 col-md-offset-1" *ngIf="subscription_id != undefined">
    <div class="table-responsive">
      <table class="table table-users">
        <thead>
          <tr>
            <th>Nachname</th>
            <th>Vorname</th>
            <th>TN / Leitung</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tn of tns">
            <td>{{tn.nachname}}</td>
            <td>{{tn.vorname}}</td>
            <td>{{tn.tn_leitung | uppercase }}</td>
            <td>
             <a [routerLink]="['/events/custom/pfadiaktion2024/editTN', tn.id]" class="btn btn-warning btn-users" *ngIf="closed == 0"> Bearbeiten</a>
           </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  